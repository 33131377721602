export function logout() {
    // Instrucciones para cerrar sesión del sistema
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
    localStorage.removeItem('username');
    localStorage.removeItem('nombre_usuario');
    localStorage.removeItem('aviso');
    localStorage.removeItem('rol_nominas');
    localStorage.removeItem('foto_usuario');
    window.location.href = `${window.location.protocol}//${window.location.hostname}/auth/login`;
}
