import './dashboard.css';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import img_jubilados from '../img/jubilados_pensionados.png'
import img_nomina from '../img/nominas.png'
import img_cuotas from '../img/cuotas.png'
import img_moras from '../img/moras.png'
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import Header from '../header/header';

function Dashboard() {

    const [aviso, setAviso] = useState(null);
    useEffect(() => {
        // Para mostrar avisos
        const ls_aviso = localStorage.getItem('aviso');
        ls_aviso && setAviso(ls_aviso);
    }, []);

    const img_style = { borderRadius: '2px 2px 0 0', height: '170px', maxWidth: '100%' };
    const hdr_jubilados = <img src={img_jubilados} alt='img_jubilados' style={img_style} />;
    const hdr_nominas = <img src={img_nomina} alt='img_nomina' style={img_style} />;
    const hdr_cuotas = <img src={img_cuotas} alt='img_nomina' style={img_style} />;
    const hdr_moras = <img src={img_moras} alt='img_nomina' style={img_style} />;

    const header_aviso = <h4 className='my-0 py-0 font-weight-light' style={{ letterSpacing: '5px' }}>
        AVISO DEL SISTEMA
    </h4>;

    return (
        <div className='all__dashboard'>
            <Header logo={true} hr={true} />
            <div className="container-fluid container__dashboard h-100">
                <Dialog header={header_aviso} style={{ width: '45vw', maxWidth: '800px' }} draggable={false} resizable maximizable
                    visible={aviso != null} onHide={() => { /* localStorage.removeItem('aviso'); */ setAviso(null); }}>
                    <h5 /* style={{ backgroundColor: 'lightgray', borderRadius: '10px' }} */ className='font-weight-bold'>{aviso}</h5>
                </Dialog>
                <div className="row text-center pt-3">
                    {/* JUBILADOS */}
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                        <a href="/jubilados/">
                            <Card header={hdr_jubilados} >
                                <Button label="JUBILADOS Y PENSIONADOS" icon="pi pi-id-card" />
                            </Card>
                        </a>
                    </div>
                    {/* NOMINAS */}
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                        <a href="/nominas/">
                            <Card header={hdr_nominas} >
                                <div className='carta'>
                                    <Button label="NÓMINAS" icon="pi pi-money-bill" className="" />
                                </div>
                            </Card>
                        </a>
                    </div>
                    {/* CUOTAS */}
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                        <a href="/cuotas/">
                            <Card header={hdr_cuotas} >
                                <Button label="CUOTAS" icon="pi pi-folder" className="" />
                            </Card>
                        </a>
                    </div>
                    {/* MORAS */}
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                        <a href="/moras/">
                            <Card header={hdr_moras} >
                                <Button label="MORAS" icon="pi pi-arrow-up-right" className="" />
                            </Card>
                        </a>
                    </div>
                </div>
                {/* SOBREVIVENCIAS */}
                <div className="row justify-content-center mt-3">
                    {/* <div className="col-md-4 col-sm-12 mb-5 px-5" >
                    <a href="https://planprestaciones.com/" target='_blank'>
                        <Card className='text-center' >
                            <Button label="RECEPCIÓN DE SOBREVIVENCIAS" icon="pi pi-external-link" />
                        </Card>
                    </a>
                </div> */}
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-5" >
                        <a href="/sobrevivencias" target="_blank" rel="noreferrer">
                            <Card className='text-center' >
                                <Button label="REPORTES SOBREVIVENCIAS" icon="pi pi-users" />
                            </Card>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer mt-5">
                <p>&copy; {new Date().getFullYear()} &bull; Procesamiento de Datos | Plan de Prestaciones</p>
            </div>
        </div>
    );
}

export default Dashboard;
