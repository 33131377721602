import './header.css';
// import usac_logo from '../img/usac_logo.png'
import gif_logo from '../img/gif_logo.gif'

function Header(props) {
    return (
        <div className='container__header' style={{ marginTop: '' }}>
            {props.logo &&
                <div className='logo__header' >
                    <img src={gif_logo} alt='logo' style={{ height: '350px', marginBottom: '5px', marginTop: '15px' }} />
                </div>
            }
            <div className='text__header' >
                {props.title}
                {props.subtitle}
            </div>
            {props.hr ?
                <hr id='hr_header' />
                : <></>
            }
        </div >
    );
}

export default Header;
