import React from 'react';
import Dashboard from '../dashboard/dashboard';
import NotFoundPage from '../notFound/404';
import { Route, Routes } from "react-router-dom";

function Rutas() {

    return (
        <Routes>
            {/* DASHBOARD */}
            <Route path="/" element={<>
                <Dashboard />
            </>} />
            {/* NOT FOUND */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );

}

export default Rutas;
