import './sidebar.css';
import usac_logo from '../img/usac_logo.png'
import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Image } from 'primereact/image';
import { logout } from '../auth/logout';

const SidebarComponent = () => {

    const [visibleLeft, setVisibleLeft] = useState(false);
    const [visibleRight, setVisibleRight] = useState(false);

    const confirm_logout = () => {
        confirmDialog({
            message: '¿Desea cerrar sesión?',
            header: 'Salir',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sí',
            rejectLabel: 'No',
            resizable: false,
            draggable: false,
            accept: () => { setVisibleRight(false); logout(); },
            reject: () => null
        });
    }

    const left_sidebar_component = () => {
        return <>{/* Sidebar por defecto */}
            <Sidebar className='menu__sidebar' visible={visibleLeft} position='left' onHide={() => setVisibleLeft(false)}>
                <div className='content__sidebar' >
                    <img src={usac_logo} alt='logo' />
                    <h3>Portal Administrativo</h3>
                    <br />
                    <ul className='text-left'>
                        <li ><a href="https://planprestaciones.com" rel="noreferrer" target='_blank'>Ir a Portal Institucional</a></li>
                    </ul>
                </div>
                <div className='footer__sidebar' >
                    <a href="mailto:itprocesamientopp@correoe.usac.edu.gt">Soporte: Ing. Luis Danniel Castellanos</a>
                </div>
            </Sidebar><Button size='large' id='btn__menu' icon="pi pi-bars" onClick={() => setVisibleLeft(true)} className="mr-2" />
        </>
    }
    const right_sidebar_component = () => {
        return <>{/* Sidebar derecho para sesión de usuario */}
            <Sidebar className='user__sidebar' visible={visibleRight} position='right' onHide={() => setVisibleRight(false)}>
                <h2 className='font-weight-bold w-fit mx-auto mb-0 px-2 py-1 status-activa' style={{ borderRadius: '4px', fontSize: '18px' }}>USUARIO ACTIVO</h2>
                {
                    localStorage.getItem('foto_usuario') ?
                        <div className='py-4 text-center' >
                            <Image className='foto_usuario' src={`data:image/plain;base64,${localStorage.getItem('foto_usuario')}`} alt="Foto" height="125" />
                        </div>
                        :
                        <div className='text-center my-4'><Avatar icon="pi pi-user" size="xlarge" /></div>
                }
                <h4 className='font-weight-bold'>{localStorage.getItem('nombre_usuario') || 'Usuario'}</h4>
                <hr />
                <Button onClick={confirm_logout} label="Cerrar sesión" icon="pi pi-sign-out" className="p-button-danger mt-2 w-100 font-weight-bold" /><ConfirmDialog />
            </Sidebar> <Button size='large' id='btn__user' icon="pi pi-user" onClick={() => setVisibleRight(true)} className="pt-0 mr-2" />
        </>
    }

    return (
        <div>
            {left_sidebar_component()}
            {right_sidebar_component()}
        </div>
    );
}

export default SidebarComponent;